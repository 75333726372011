import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/', //首页
        name: 'HomeList',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/html/index', //PC端主页
        name: 'Index',
        component: () => import('../views/Index.vue')
    },
    {
        path: '/html/app/home', //移动端主页
        name: 'Home',
        component: () => import('../views/app/Home.vue')
    },
    {
        path: '/html/app/plant', //植物详情页
        name: 'Plant',
        component: () => import('../views/app/Plant.vue')
    },
    {
        path: '/html/app/longtext', //长文详情页
        name: 'LongText',
        component: () => import('../views/app/LongText.vue')
    },
    {
        path: '/html/app/liferecord', //美图详情页
        name: 'LifeRecord',
        component: () => import('../views/app/LifeRecord.vue')
    },
    {
        path: '/html/app/about', //关于app
        name: 'About',
        component: () => import('../views/app/About.vue')
    },
    {
        path: '/html/app/agreement', //用户协议
        name: 'Agreement',
        component: () => import('../views/app/Agreement.vue')
    },
    {
        path: '/html/protocol/privacy', //隐私协议
        name: 'Privacy',
        component: () => import('../views/protocol/Privacy.vue')
    },
    {
        path: '/html/app/business', //商务合作
        name: 'Business',
        component: () => import('../views/app/Business.vue')
    },
    {
        path: '/html/app/homepage', //个人主页
        name: 'Business',
        component: () => import('../views/app/Homepage.vue')
    },
    {
        path: '*',
        name: 'Error',
        component: () => import('../views/Error.vue')
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes: routes
})

export default router
