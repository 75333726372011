// const url = 'http://39.104.129.120:8081'
const url = 'https://smallplants.cn'
const url_web_detail = url + "/api/app/v1/web/detail"; //移动端plant页面 植物详情
const url_web_info = url + "/api/app/v1/web/home/info";//移动端homwpage页面 基本信息
const url_web_info_v2 = url + "/api/app/v2/web/home/info"; // v2 移动端个人主页 基本信息
const url_web_plantlist = url + "/api/app/v1/web/home/plants/list";//移动端homwpage页面 成长记
const url_web_plantlike = url + "/api/app/v1/web/home/likes/list";//移动端homwpage页面 喜欢
const url_web_plantlist_v2 = url + "/api/app/v2/web/home/plants/list";//移动端homwpage页面 成长记、长文、美图等
const url_web_longtext_detail = url + "/api/app/v2/web/detail/longtext"; //移动端 长文详情
const url_web_liferecord_detail = url + "/api/app/v2/web/detail/lifeRecord"; //移动端 美图详情

export default {
    url_web_detail,
    url_web_info,
    url_web_info_v2,
    url_web_plantlist,
    url_web_plantlike,
    url_web_plantlist_v2,
    url_web_longtext_detail,
    url_web_liferecord_detail
}