import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import './font/index.css'
import axios from 'axios'
import appUrl from './global/appUrl.js'
import apiUrl from './global/apiUrl.js'

Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.appUrl = appUrl
Vue.prototype.apiUrl = apiUrl
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
