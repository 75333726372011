import Vue from 'vue'
import Vuex from 'vuex'
// import weui from 'weui.js'
// import 'weui'
//
// Vue.prototype.$weui = weui
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
