import $ from "jquery";

const down_url_iphone = "itms-apps://itunes.apple.com/cn/app/jie-zou-da-shi/id1580659779?mt=8"; //Iphone下载链接
const down_url_android = "https://xzwcn.oss-cn-shanghai.aliyuncs.com/apk/xzw_1.0.1_11012148.apk"; //Android下载链接

const url_iphone = "itms-apps://itunes.apple.com/cn/app/jie-zou-da-shi/id1580659779?mt=8"; //Iphone唤起APP链接
const url_android = "xzw://smallplants.cn/app/splash"; //Android唤起APP链接

function alertErr(msg) {
  document.head.innerHTML =
      '<title>抱歉，出错了</title><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0"><link rel="stylesheet" type="text/css" href="https://res.wx.qq.com/open/libs/weui/0.4.1/weui.css">';
  document.body.innerHTML =
      `<div class="weui_msg"><div class="weui_icon_area"><i class="weui_icon_info weui_icon_msg"></i></div><div class="weui_text_area"><h4 class="weui_msg_title">${msg}</h4></div></div>`;
  return;
}
function onlyMo() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/iPhone/i) == "iphone") {
    //iphone
  } else if (ua.match(/Android/i) == "android") {
    //android
    $("#app").css("font-family", "Microsoft Yahei");
  } else {
    alertErr('请使用移动端登录');
  }
}
function onlyPc() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/iPhone/i) == "iphone") {
    //iphone
    alertErr("请使用PC端登陆");
  } else if (ua.match(/Android/i) == "android") {
    //android
    alertErr("请使用PC端登陆");
  }
}
export default {
  down_url_iphone, down_url_android, url_iphone, url_android, alertErr, onlyMo, onlyPc
}